.app {
  padding: 0 20px 20px 20px;
  border-bottom: solid #d9d9d8 1px;

  &:last-of-type {
    border-bottom: none;
  }

  & picture > img {
    max-height: 220px;
    max-width: 220px;
    object-fit: scale-down;
    float: left;
    display: block;
    margin-top: -25px;
    margin-right: 50px;
    vertical-align: middle;
  }

  & > .title {
    top: 25px;
    font-size: 34px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1px;
  }

  & > .description {
    margin-top: 10px;
    font-size: 21px;
    font-weight: 200;
    line-height: 30px;
  }

  & > .links {
    margin-top: 20px;

    a {
      display: inline-block;

      img {
        height: 51px;
        object-fit: scale-down;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .app {
    & picture > img {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }

    & > .links {
      text-align: center;

      a {
        margin-top: 10px;
      }
    }

    & > p {
      text-align: center;
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .app {
    & picture > img {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }

    & > .links {
      text-align: center;

      a {
        margin-top: 10px;
      }
    }

    & > p {
      text-align: center;
    }
  }
}