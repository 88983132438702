.ads {
  height: 112px;
  margin: 25px 0;
  padding: 25px 0;
  border-top: 1px solid $light;
  border-bottom: 1px solid $light;
  text-align: right;

  .adsbygoogle {
    display: inline-block;
    width: 468px;
    height: 60px
  }
}

.ads:after {
  width: 100%;
  content: "Ads";
  text-align: right;
  float: right;
  margin-right: 3px;
  color: $text-muted;
}

@media only screen and (max-width: 600px) {
  .ads {
    text-align: center;

    .adsbygoogle {
      width: 100%;
    }
  }
}