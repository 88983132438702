.btn-orange {
  color: #000000;
  background-color: $orange;
  border-color: $orange;
}

.btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  background-color: #52b4d4;
  background-image: linear-gradient(to bottom,#62bbd8,#39a9ce);
  background-repeat: repeat-x;
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
}

.btn-paypal {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  background-color: #f66951;
  background-image: linear-gradient(to bottom,#f6715a,#f55c42);
  background-repeat: repeat-x;
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
}

.btn-mini {
  font-size: 10.5px !important;
  padding: 0 6px !important;
  border-radius: 3px;
  margin: 0 !important;
  line-height: 20px !important;
}

.btn-default {
  color: #555d64;
  text-shadow: 0 1px 1px rgb(255 255 255 / 75%);
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom,#fff,#e6e6e6);
  background-repeat: repeat-x;
  border-color: rgba(0,0,0,.15) rgba(0,0,0,.15) rgba(0,0,0,.25);
}