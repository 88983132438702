header {
  &.carousel {
    margin-top: 50px;
    height: 320px;
    background: #21272f url("../img/pattern-denim.png") repeat;

    .carousel-img {
      object-fit: cover;
      height: 320px;
      object-position: 100% 0;
      float: right;
      padding-top: 55px;
    }

    .carousel-item {
      .carousel-caption {
        position: relative;
        text-align: left;
        top: 70px;
        width: 650px;
        height: 100%;
        left: 15%;

        .carousel-title {
          font-size: 32px;
          margin: 0;
          line-height: 1.25;
          color: #fff;
        }

        .carousel-description {
          font-size: 18px;
          margin: 0;
          line-height: 1.25;
          color: #fff;
          font-weight: 200;

          .text-muted {
            font-size: 16px;
            color: #6f6f6f !important;
          }
        }

        .btn {
          margin: 10px 10px 0 0;
        }
      }
    }

    .carousel-footer {
      box-sizing: unset;
      height: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .5);
      padding: 20px 0;
      border-bottom: 1px solid #fff;

      a {
        z-index: 9999;
      }

      ul {
        list-style: none;
        text-align: right;
        margin-right: 20px;

        li {
          display: inline-block;
          padding: 0;
          vertical-align: top;

          .btn {
            margin-top: -2px;
            margin-left: 15px;
            padding: 0 6px;
            font-size: 14px;
          }
        }
      }
    }

    &.carousel-page {
      height: 250px;

      .carousel-img {
        height: 250px;
        padding-top: 15px;
        padding-right: 55px;
      }

      .carousel-caption {
        top: 30px;
        bottom: auto;
        left: 5%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  header {
    &.carousel {
      height: 250px;
      margin-top: 56px;

      .carousel-img {
        display: none;
      }

      .carousel-item {
        .carousel-caption {
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          text-align: center;

          p {
            display: block;
          }

          .carousel-title {
            padding-left: 20px;
            padding-right: 20px;
          }

          .carousel-description {
            .text-muted {
              display: none;
            }
          }
        }
      }

      .carousel-footer {
        .container {
          margin: 0;
        }

        ul {
          text-align: center;
        }

        li {
          .btn {
            margin: 0;
          }
        }
      }

      &.carousel-page {
        height: unset;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  header {
    &.carousel {
      margin-top: 0;

      .carousel-item {
        .carousel-caption {
          position: absolute;
          left: 10%;
          right: 10%;
        }
      }
    }
  }
}