/*
  Default blue        : #39a9ce;
  Default text-muted  : #6f6f6f;
 */

// Colors
$white: #ffffff;
$orange: #f55c42;
$secondary: #6c757d;
//$blue: #1e596c;
$blue: #39a9ce;
$light: #d9d9d8;
$text-muted: #4c5359;
$light-blue: #39a9ce;
$dark-blue: #28465b;
$extra-light-blue: #c8e7f1;
$navbar-dark-background-color: #252b34;
$navbar-dark-background-active-color: #1a1f25;
$default-color: #555d64;

// Configurations
$carousel-background: url("../img/pattern-denim.png");