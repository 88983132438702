.navbar {
  padding: 0 20px;
  font-size: 14px;
}

.navbar-dark {
  background-color: $navbar-dark-background-color;
  background-image: -moz-linear-gradient(top, #282f38, #20262e);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#282f38), to(#20262e));
  background-image: -webkit-linear-gradient(top, #282f38, #20262e);
  background-image: -o-linear-gradient(top, #282f38, #20262e);
  background-image: linear-gradient(to bottom, #282f38, #20262e);
  background-repeat: repeat-x;
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  background-color: $navbar-dark-background-active-color;
}

.navbar-nav .nav-link {
  padding: 15px 20px 15px 20px !important;
}

.navbar-toggler {
  font-size: 1rem;
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .navbar-nav .nav-link {
    font-size: 1rem;
    padding: 8px 10px 8px 10px !important;
  }

  .dropdown-menu {
    background-color: transparent !important;
    border: none !important;
    padding: 0 0 0 0;
    margin-top: 0;
  }

  .dropdown-divider {
    margin: 8px 8px 4px 8px;
  }
}