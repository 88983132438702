.text-orange {
  color: $orange;
}

.text-dark-blue {
  color: $dark-blue !important;
}

.text-default {
  color: $default-color !important;
}