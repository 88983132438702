.product {
  padding: 20px 0;
  width: 100%;
}

.product img, .product-img {
  vertical-align: top;
  max-width: 175px;
  max-height: 175px;
}

.product .product-content {
  display: inline-block;
  vertical-align: center;
  padding-top: 15px;
  padding-left: 30px;
  overflow-wrap: anywhere;
}

.product .product-content h1 {
  font-size: 32px;
}

.product .product-content h2 {
  color: $text-muted;
  font-size: 34px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px;
}

.product .product-content p {
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  overflow-wrap: anywhere;
}

@media only screen and (max-width: 600px) {
  .product {
    padding: 0;
    text-align: center;
  }

  .product img, .product-img {
    max-width: 80px;
    max-height: 80px;
    object-fit: contain;
  }

  .product .product-content {
    display: block;
    vertical-align: center;
    padding: 0;
  }

  .product .product-content h2 {
    font-size: 30px;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .product img, .product-img {
    max-width: 120px;
    max-height: 120px;
    object-fit: contain;
  }
}