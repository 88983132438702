.sub-navbar {
  top: 50px;
}

.sub-navbar .navbar-nav {
  padding: 5px 0 !important;

  max-width: 100%;

  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.sub-navbar .navbar-nav > .nav-item:not(:first-child), .sub-navbar .navbar-nav > .navbar-nav:not(:first-child) {
  margin-left: -1px;
}

.sub-navbar .navbar-nav > .nav-item:not(:last-child):not(.dropdown-toggle), .sub-navbar .navbar-nav > .navbar-nav:not(:last-child) > .nav-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sub-navbar .navbar-nav > .nav-item:nth-child(n+3), .sub-navbar .navbar-nav > :not(.btn-check) + .nav-item, .sub-navbar .navbar-nav > .navbar-nav:not(:first-child) > .nav-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sub-navbar .navbar-nav > .nav-item {
  display: inline-block;
  font-weight: 400;
  line-height: 0.5;
  color: $blue !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #d9d9d8;
  padding: 0;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sub-navbar .navbar-nav > .nav-item > .nav-link {
  color: $blue;
}

.sub-navbar .navbar-nav > .nav-item > .nav-link:hover, .sub-navbar .navbar-nav > .nav-item > .nav-link.active {
  color: $white;
  background-color: $blue;
}

.sub-navbar .navbar-nav > .nav-item:hover {
  border: 1px solid #d9d9d8;
}


.sub-navbar .navbar-nav > .nav-item.disabled {
  cursor: default;
  background-color: #ffffff;

  .nav-link {
    color: #555d64;
    font-weight: bold;
  }
}


@media only screen and (max-width: 600px) {
  .sub-navbar {
    top: 0;
    height: 0;
    display: none;
  }
}