body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #555d64;
  background-color: #f3f3f2;
}

a {
  text-decoration: none;
}

main:focus, .scrollspy:focus {
  outline: none;
}

footer a {
  color: $light-blue;
}

@media only screen and (max-width: 600px) {
  .content {
    text-align: center;
  }
}