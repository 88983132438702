.products {

}

.products .list-group {

}

.products .list-group .list-group-item {
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.products .list-group .list-group-item:hover {
  background-color: $white;
}

.products .list-group .list-group-item .list-group-item-title {
  color: $blue;
}

.products .list-group .list-group-item .list-group-item-description {
  font-size: 75%;
  color: $text-muted;
}

.products .list-group .list-group-item .list-group-item-icons {
  float: right;
  color: $text-muted;
  display: inline;
}

.products .list-group .list-group-item .list-group-item-icons i {
  padding-left: 4px;
}

.products .list-group .list-group-item .list-group-item-icons a {
  display: inline;
  padding-left: 4px;
  color: $text-muted;
}