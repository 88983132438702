.v-divider {
  border-right-color: $light;
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 20px;
}

.accordion-collapse {
  border: none;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-extra-light-blue {
  background-color: $extra-light-blue;
}

.img-fluid {
  object-fit: scale-down;
}

.award {
  padding: 10px 0;

  a {
    img {
      max-width: 100%;
      max-height: 120px;
    }
  }
}

.blog {
  margin-top: 50px;

  p {
    font-size: 21px;
    font-weight: 200;
    line-height: 30px;
    margin: 20px 0;
  }

  img {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    display: block;
  }
}

.cc-revoke {
  width: 125px;
  right: 0;
  left: auto;
  margin-right: 20px;
}

.cc-animate.cc-revoke {
  -webkit-transition: transform 1s ease;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease
}

.cc-animate.cc-revoke.cc-bottom {
  -webkit-transform: translateY(2em);
  transform: translateY(2em)
}

.cc-animate.cc-revoke.cc-active.cc-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.cc-revoke:hover {
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.cc-revoke, .cc-window {
  position: fixed;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999
}
